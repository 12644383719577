<template>
  <div>
    <b-table-custom
        :table-data="dataObjectList"
        :table-columns="tableColumns"
        :is-data-loading="isDataLoading"
    >
      <template #cell(id)="{data}">
        <a :href="`/invoice/detail/${data['item']['id']}`">
          <strong>#{{ data['item']['id'] }}</strong>
        </a>
      </template>
      <template #cell(view_detail)="{data}">
        <b-badge
            :href="`/invoice/detail/${data['item']['id']}`"
            variant="primary"
        >
          <div class="d-flex align-items-center">
            <feather-icon
                icon="EyeIcon"
                class="mr-25"
            />
            <span>Chi tiết</span>
          </div>
        </b-badge>
      </template>
      <template #cell(total_amount)="{data}">
        <strong>{{ currencyFormat(data['item']['total_amount'] || 0) }}</strong>
      </template>
      <template #cell(total_income)="{data}">
        <strong class="text-success">{{ currencyFormat(data['item']['total_income'] || 0) }}</strong>
      </template>
      <template #cell(total_actual)="{data}">
        <strong class="text-info">{{ currencyFormat(data['item']['total_actual'] || 0, 'en-US', 'USD') }}</strong>
      </template>
      <template #cell(type)="{data}">
        <b-badge :variant="`light-${invoiceType(data['item']['type']).variant}`">
          <div class="d-flex align-items-center">
            <feather-icon
                :icon="invoiceType(data['item']['type']).icon"
                class="mr-25"
            />
            <span>{{ invoiceType(data['item']['type']).label }}</span>
          </div>
        </b-badge>
      </template>
      <template #cell(status)="{data}">
        <b-badge :variant="`light-${invoiceStatus(data['item']['status']).variant}`">
          <div class="d-flex align-items-center">
            <feather-icon
                :icon="invoiceStatus(data['item']['status']).icon"
                class="mr-25"
            />
            <strong>{{ invoiceStatus(data['item']['status']).label }}</strong>
          </div>
        </b-badge>
      </template>
      <template #cell(payment_method)="{data}">
        <b-badge
            :variant="paymentMethod(data['item']['payment_method']).variant"
        >
          <div class="d-flex align-items-center">
            <feather-icon
                :icon="paymentMethod(data['item']['payment_method']).icon"
                class="mr-25"
            />
            <span>{{ paymentMethod(data['item']['payment_method']).label }}</span>
          </div>
        </b-badge>
      </template>
    </b-table-custom>
  </div>
</template>

<script>
import BTableCustom from '@/views/components/table/BTableCustom.vue'

import inputHelper from '@/mixins/utils/inputHelper'
import dataRequester from '@/mixins/utils/dataRequester'
import dataObjectList from '@/mixins/utils/dataObjectList'
import invoiceService from "@/mixins/utils/axios/invoice.service";

export default {
  name: 'InvoiceList',
  components: {
    BTableCustom
  },
  mixins: [inputHelper, dataRequester, dataObjectList],
  data () {
    let self = this
    return {
      tableColumns: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'type',
          label: 'Phân Loại'
        },
        {
          key: 'total_amount',
          label: 'Tổng tiền'
        },
        {
          key: 'total_income',
          label: 'Lợi tức'
        },
        {
          key: 'total_actual',
          label: 'Tổng thực tế'
        },
        {
          key: 'status',
          label: 'Trạng thái'
        },
        {
          key: 'payment_method',
          label: 'P.Thức thanh toán'
        },
        {
          key: 'note',
          label: 'Ghi chú'
        },
        {
          key: 'user',
          label: 'Người dùng'
        },
        {
          key: 'created_by',
          label: 'Người tạo'
        },
        {
          key: 'created_at',
          label: 'Ngày tạo'
        },
        {
          key: 'view_detail',
          label: 'Xem chi tiết'
        }
      ],
      isDataLoading: false
    }
  },
  computed: {
    invoiceType() {
      return (type) => {
        switch (type) {
          case 'Renew':
            return {
              label: 'Gia hạn',
              icon: 'RepeatIcon',
              variant: 'success'
            }
          case 'Topup':
            return {
              label: 'Nạp tiền',
              icon: 'DollarSignIcon',
              variant: 'info'
            }
          default:
            return {
              label: 'Không xác định',
              icon: 'AlertTriangleIcon',
              variant: 'secondary'
            }
        }
      }
    },
    invoiceStatus() {
      return (status) => {
        switch (status) {
          case 'Unpaid':
            return {
              label: 'Chưa thanh toán',
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          case 'Pending':
            return {
              label: 'Chờ xử lý',
              icon: 'ClockIcon',
              variant: 'warning'
            }
          case 'Finished':
            return {
              label: 'Hoàn thành',
              icon: 'CheckCircleIcon',
              variant: 'success'
            }
          case 'Canceled':
            return {
              label: 'Đã hủy',
              icon: 'XCircleIcon',
              variant: 'secondary'
            }
          default:
            return {
              label: 'Không xác định',
              icon: 'AlertTriangleIcon',
              variant: 'secondary'
            }
        }
      }
    },
    paymentMethod() {
      return (method) => {
        switch (method['type']) {
          case 'Bank':
          case 'Wallet':
          case 'Balance':
            return {
              label: method['name'],
              icon: 'CreditCardIcon',
              variant: 'info'
            }
          default:
            return {
              label: 'Không xác định',
              icon: 'AlertTriangleIcon',
              variant: 'secondary'
            }
        }
      }
    }
  },
  created() {
    this.getInvoiceList()
  },
  methods: {
    getInvoiceList () {
      this.isDataLoading = true
      this.runApi(invoiceService.listAllInvoices(), (result) => {
        this.dataObjectList = result.data
        this.isDataLoading = false
      })
    }
  }
}

</script>

<style scoped lang="scss">

</style>
